setTimeout(function(){
    $('.js-slider').slick(
        {
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 2000,
            fade: true
        }
    );
    $('.js-slider-pc').slick(
        {
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 2000,
            fade: true
        }
    );
},500);