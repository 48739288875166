
$(function () {
    var openFlag = false;
    $('.js-toggle-button').on('click', function () {
        //$(this).toggleClass('close');
        $('.js-toggle-menu').toggleClass('open');
        if(openFlag == false){
            $('body').addClass('open');
            openFlag = true;
        }else{
            $('body').removeClass('open');
            openFlag = false;
        }
    });

    // $('.js-menu-parent').on('click', function () {
    //     $(this).toggleClass('open');
    //     $(this).parent().find('.js-menu-child').slideToggle();
    // });

    $(window).on('load resize', function(){
        var wWidth = $(window).width();
        if(wWidth > 768){
            $('body').removeClass('open');
            openFlag = false;
            $('.js-toggle-menu').removeClass('open');
        }
    });
});