//スクロールに合わせて表示するエフェクト
//scss内のjs/move.scssと合わせて使用
//左から右へ：.js-move-left
//右から左へ：.js-move-right
//下から上へ：.js-move-up
//上から下へ：.js-move-down
//フェードイン：.js-move-fade
//
//ページアクセス時に発火する場合は.js-move-startを併記する

//ロードアクション
$(window).on('load', function(){
    var target = $('.js-move-start');
    target.each(function(){
        setTimeout(function(){
            target.addClass('on');
        }, 500);
    });
});

//スクロールアクション
$(window).on('scroll', function () {
    var sTop = $(window).scrollTop();

    var wWidth = $(window).width();

    var aPos = wWidth < 420 ? 600 : 500;
    var dRate = wWidth < 420 ? 1 : 100;

    var target = $('.js-move');
    target.each(function () {
        var tTop = $(this).offset().top;
        if ((tTop - sTop) < aPos) {
            var delay = $(this).attr('data-delay');
            var dTime = 0 + delay * dRate;
            $(this).delay(dTime).queue(function (next) {
                //$(this).removeClass('out');
                $(this).addClass('on');
                next();
            });
        }
    });

    var targetSet = $(document).find('.js-move-set');
    targetSet.each(function(){
        var tTop = $(this).offset().top;
        //console.log('tTop:' + tTop)
        if ((tTop - sTop) < aPos) {
            $(this).find('.js-move-child').each(function(){
                var delay = $(this).attr('data-delay');
                var dTime = 0 + delay * dRate;
                $(this).delay(dTime).queue(function (next) {
                    //$(this).removeClass('out');
                    $(this).addClass('on');
                    next();
                });
            })

            $(this).find('.js-action-txt').each(function(){
                $(this).css('opacity',1).textillate({
                    loop: false,
                    autoStart: true,
                    in: {
                        effect: 'fadeIn',
                        sync: true,  
                        delay: 500,
                    }
                })
            })
        }
    })
});